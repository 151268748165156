import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { colorPrimary, colorSecondory } from "../../constants";
import { useEffect, useState } from "react";
import { API_URL } from "../../constants";
import MyPieChart from "../../components/PieChart";
import { ChartStaticsDto, Configuration, ConfigurationParameters, ReportApi, TowMonthChartStaticsDto } from "../../api/najd";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { Categories } from "../categories/Categories";

export default () => {
  const { t } = useTranslation();
  const [data, setData] = useState<TowMonthChartStaticsDto[]>([]);
  const auth = useAuth();
  
  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const reportApi = new ReportApi(configuration);


  useEffect(() => {
    getData();
  }, []); // Run this effect only once when the component mounts

  const getData = () => {
    reportApi.reportItemCategorySalesForYearStatics()
      .then((response) => {
        setData(response.data);
        const targetList = convertList(response.data.filter((x: ChartStaticsDto) =>x.dateStr === '2024' ||x.dateStr === '2023'));
        console.log("==>",targetList);
        setdataGrouped(targetList);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  };

  const [dataGrouped, setdataGrouped] = useState<TargetObject[]>([]);
  type GroupedDataItem = {
    date: string,
    data: {
      [Categories: string]: number
    }
  };
  
  interface TargetObject {
    [key: string]: number;
  }

  function convertList(sourceList: ChartStaticsDto[]): TargetObject[] {
    const result: TargetObject[] = [];

    sourceList.forEach((item: any) => {
      const existingItem = result.find((targetItem: any) => targetItem.name === item.name);

      if (existingItem) {
        existingItem[item.dateStr] = item.value;
      } else {
        const newItem: TargetObject = {
          name: item.name,
          [item.dateStr]: item.value
        };
        result.push(newItem);
      }
    });

    return result;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        // width={600}
        // height={400}
        data={dataGrouped}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar name="2024" dataKey="2024" fill={colorPrimary} />
        <Bar name="2025" dataKey="2025" fill={colorSecondory} />
      </BarChart>
    </ResponsiveContainer>
  );

};